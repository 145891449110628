import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { t } from 'i18next';

export interface IViewDetailsButtonProps {
    onClick: () => void | React.MouseEventHandler<HTMLButtonElement>;
    isEnabled?: boolean;
}

export function ViewDetailsButton({ onClick, isEnabled = true }: IViewDetailsButtonProps) {
    return (
        <Tooltip title={`${t('Invite')}`}  arrow placement="bottom">
            <IconButton onClick={onClick} disabled={!isEnabled}>
                <InfoIcon color="primary"/>
            </IconButton>
        </Tooltip>
    );
}
