import { IconButton, Tooltip } from '@material-ui/core';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { selectCalendarFilterScheduledTasks } from 'store/modules/task/calendarFilter/calendarFilterSelectors';
import { calendarFilterScheduledTasks } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';
import TimerIcon from '@material-ui/icons/Timer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useTaskFilterStyles } from '../../taskFilterStyles';

export const CalendarScheduledTasksFilter = () => {
  const isFilterScheduledButtonEnabled = useFeatureState(FeatureFlags.TasksCalendarViewHideScheduledButton);
  const canAccessTask = useSelector(selectHasAppAccess(AppName.Tasks));
  const filterScheduledTasks = useSelector(selectCalendarFilterScheduledTasks);
  const actions = useActions({ calendarFilterScheduledTasks });
  const classes = useTaskFilterStyles();
  const title = useMemo(()=> filterScheduledTasks ? `${t('Show Scheduled')}` : `${t('Hide Scheduled')}`, [filterScheduledTasks])

  const handleFilterScheduledTasks = useCallback(() => {
      actions.current.calendarFilterScheduledTasks(!filterScheduledTasks)
  },[filterScheduledTasks, actions])

  if(!isFilterScheduledButtonEnabled || !canAccessTask) return <></>;

  return (
    <Tooltip title={title}>
      <IconButton size='small' onClick={handleFilterScheduledTasks}>
        {filterScheduledTasks ? 
          <VisibilityOffIcon color="primary" className={classes.calendarScheduledTasksFilterIcon}/>
          : <VisibilityIcon color="primary" className={classes.calendarScheduledTasksFilterIcon}/>
        }
        <TimerIcon color="primary" fontSize='medium'/>
      </IconButton>
    </Tooltip>
  )
}
